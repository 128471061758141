import React from "react"
import { graphql, Link } from "gatsby"
import { Themed, Box } from "theme-ui"
import Layout from "gatsby-theme-blog/src/components/layout"
import TagList from "../components/tag-list"

export const query = graphql`
  query BlogPostsByTag($tag: String!) {
    allBlogPost(
      sort: { fields: [date, title], order: DESC }
      filter: { tags: { in: [$tag] } }
    ) {
      nodes {
        id
        title
        date(formatString: "YYYY-MM-DD")
        excerpt
        slug
        tags
      }
    }
  }
`

const Tag = ({ data, pageContext: { tag }, location }) => {
  const posts = data.allBlogPost.nodes

  return (
    <Layout location={location} title={`タグ: ${tag}`}>
      <Box sx={{ mb: 4 }}>
        <Themed.h1>タグ: {tag}</Themed.h1>
        <Link
          to="/tags"
          sx={{
            color: "primary",
            textDecoration: "none",
            "&:hover": {
              textDecoration: "underline"
            }
          }}
        >
          ← タグ一覧に戻る
        </Link>
      </Box>

      {posts.map((post) => (
        <article key={post.id}>
          <header>
            <Themed.h2>
              <Themed.a as={Link} to={post.slug}>
                {post.title}
              </Themed.a>
            </Themed.h2>
            <small>{post.date}</small>
            <TagList tags={post.tags} />
          </header>
          <section>
            <Themed.p>{post.excerpt}</Themed.p>
          </section>
        </article>
      ))}
    </Layout>
  )
}

export default Tag
