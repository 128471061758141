import React from "react"
import { Link } from "gatsby"
import { Badge, Box } from "theme-ui"

const TagList = ({ tags }) => {
  if (!tags || tags.length === 0) return null

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: 2,
        my: 2
      }}
    >
      {tags.map((tag) => (
        <Link
          key={tag}
          to={`/tags/${tag}`}
          style={{
            textDecoration: "none"
          }}
        >
          <Badge
            variant="tag"
            sx={{
              "&:hover": {
                backgroundColor: "secondary",
                transition: "background-color 0.2s"
              }
            }}
          >
            {tag}
          </Badge>
        </Link>
      ))}
    </Box>
  )
}

export default TagList
